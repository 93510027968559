<template>
	<core-section id="what-ive-done">
		<!-- <abstract-3 /> -->

		<core-heading>Mon travail</core-heading>

		<v-col cols="12">
			<v-carousel :cycle="false" :height="650" :show-arrows="false" class="elevation-0" hide-delimiter-background>
				<v-carousel-item v-for="(project, i) in projects" :key="i">
					<project :value="project" />
				</v-carousel-item>
			</v-carousel>
		</v-col>
	</core-section>
</template>

<script>
	export default {
		name: "WhatIveDone",

		components: {
			Abstract3: () => import("@/components/Abstract3"),
			Project: () => import("@/components/Project"),
		},

		data: () => ({
			projects: [
				{
					name: "Valy by Lœsus Pedagogia",
					category: "Développement d'application",
					src: "valy.png",
                    info: "Conception, développement et mise en place (architecture, backend et frontend) de la plateforme de validation et de certification nouvelle génération \"Valy\" de Lœsus Pedagogia ainsi que de tous ses outils externes (système de sauveguarde, outils de suivi des revenus, système de monitoring ...).",
                    link:"https://valy.loesuspedagogia.fr"
				},
				{
					name: "loesuspedagogia.fr",
					category: "Développement de site web",
					src: "LP.png",
                    info: "Conception et développement du site vitrine de Lœsus Pedagogia.",
                    link:"https://loesuspedagogia.fr"
				},
				{
					name: "my-learning-coach.fr",
					category: "Développement de site web",
					src: "MLC.png",
                    info: "Conception et développement du site vitrine de My learning coach.",
                    link:"https://my-learning-coach.fr"
				},
				{
					name: "Ludimation Business Game V2",
					category: "Développement d'application",
					src: "v2.png",
                    info: "Conception, développement et mise en place (architecture, backend et frontend) de la nouvelle plateforme de business game et de simulation d'entreprise de Ludimation.",
                    link:"https://evolution.ludimation.com/"
				},
				{
					name: "ludimation.com",
					category: "Développement de site web",
					src: "ludi.png",
                    info: "Conception et développement du site vitrine de Ludimation.",
                    link:"https://ludimation.com/"
				},
				{
					name: "Quinten",
					category: "Développement de site web",
					src: "QTN.png",
                    info: "Conception et développement du nouveau site vitrine de Quinten.",
                    link:"https://quinten.ai/"
				},
				{
					name: "K-ORDY",
					category: "Développement de site web",
					src: "Kordy.png",
                    info: "Conception et développement du nouveau site vitrine de K-ORDY.",
                    link:"#"
				},
				{
					name: "Ludimation Business Game V1",
					category: "Mise a jour/Mise a niveau d'application",
					src: "JProd.png",
                    info: "Mise a jour, securisation, optimisation et ajout de fonctionalité pour la plateforme de business game et de simulation d'entreprise de Ludimation.",
                    link:"https://jeuprod.ludimation.com/"
				},
			],
		}),
	}
</script>
